import { PaymentMethods } from '@iris/store/types'
import feathers, { Service, NullableId, Id, ServiceMethods } from '@feathersjs/feathers'
import { AxiosStatic } from 'axios'
import rest from '@feathersjs/rest-client'
import { autoAuthAxios } from './axios'
import Api from '@iris/api'
import { Stripe } from 'stripe'
import type { PaymentIntent, SetupIntent } from '@stripe/stripe-js'

export interface IPaymentApi {
  _id?: string;
  currency: 'gbp';
  amountInCents: number;
  irisVersion: string;
  userId: number;
  consultant: string;
  office: string;
  regHitId: number;
  ipAddress?: string;
  irisUrl?: string;
  createdAt?: string;
  updatedAt?: string;
  subscriberId: number;
  subscriberSurname: string;
  subscriberPostcode: string;
  saleType: PaymentMethods;
  receiptEmail: string;
  stripeClientSecret?: string;
  stripePaymentIntentId?: string;
  stripeIntentStatus?: PaymentIntent['status'];
  subscription?: Id;
  setupFutureUsage?: Stripe.PaymentIntent['setup_future_usage'];
}

/**
 * coerce a title into a specific list
 * @param title the input title
 */
export function coerceTitleToManualDirectDebitTitle (title?: string | null): ManualDirectDebitInfo['title'] {
  switch (title) {
    case 'Mr':
      return 'Mr'
    case 'Mrs':
      return 'Mrs'
    case 'Miss':
      return 'Miss'
    case 'Ms':
      return 'Ms'
    default:
      return 'unknown'
  }
}
/** direct debit info to import into 3J */
export interface ManualDirectDebitInfo {
  /** record was exported at - when undefined the record hasn't been exported yet */
  exportedAt?: string | null;
  title: 'Mr' | 'Mrs' | 'Miss' | 'Ms' | 'unknown';
  homePhone?: string;
  workPhone?: string;
  mobilePhone: string;
  firstName: string;
  lastName: string;
  dob: string;
  address1: string;
  address2?: string;
  city: string;
  postcode: string;
  /** monthly instalment amount */
  monthlyInstallment: number;
  /** initial payment amount */
  initialPayment: number;
  firstRepaymentDate: string;
  bankName: string;
  accountNumber: string;
  /** in format NN-NN-NN where N is the number */
  sortCode: string;
  accountName: string;
  saleDate: string;
  /** array of products */
  products: string[];
}

export interface ISubscription {
  _id?: string;
  office: string;
  subscriberId: number;
  firstName: string;
  lastName: string;
  email: string;
  address1: string;
  address2: string;
  city: string;
  postcode: string;
  state: string;
  country: string;
  phone: string;
  regHitId: number;
  consultant: string;
  instituteCode: string;
  saleType: 'LTL' | 'PAYG' | 'CASH' | 'APS' | 'STRIPE' | 'APS_SUBSCRIPTION' | 'SP01' | 'SP06' | 'SP12' | 'CP12' | 'SP03' | 'LTS10' | 'SP30' | 'GCSE' | 'CP126';
  /** number of months deposit */
  monthsDeposit: number;
  stripeCustomer?: Stripe.Customer;
  stripeSetupIntents?: Array<{
    intent: Stripe.SetupIntent;
  }>;
  /** 3j direct debit reporting data */
  manualDirectDebit?: ManualDirectDebitInfo;
}

export interface ISubscriptionSetupIntent {
  _id?: string;
  intent: Pick<SetupIntent, 'client_secret' | 'status'>
}

export interface IrisJwtAuthPayload {
  /** subject usually user id */
  sub: string;
  /** user id as number */
  userid: number;
  /** username */
  username: string;
  /** first name */
  lastName: string;
  /** last name */
  firstName: string;
  /** default office */
  office: string;
  /** is an super admin user */
  isAdminUser: boolean;
  /** is an branch admin user */
  isBranchUser: boolean;
  /** db2 database granted auth */
  grantedAuthorities: string[];
  /** token issuer */
  iss: string;
  /** token audience */
  aud: string;
  /** Token expiry */
  exp: number;
  /** Token not before */
  nbf: number;
}

export interface IScheduledPayment {
  _id: NullableId;
  subscription: string | ISubscription; // our DB
  // eslint-disable-next-line camelcase
  payment_method: string; // from stripe
  amountInCents: number;
  description: string;
  debitOn: string;
  paymentMethodResponse?: Stripe.PaymentMethod;
  invoice?: Stripe.Invoice;
  paymentIntent?: Stripe.PaymentIntent;
  disabled?: boolean;
  /** payload from jwt auth can't be set on client */
  auth?: IrisJwtAuthPayload;
  /** subscribers office needs to be set to create */
  subscriberOffice: string;
  createdAt?: string;
  updatedAt?: string;
  subscriberId: number;
  surname: string;
  postcode: string;
}

/* source for this is in IRIS-UI-BACKEND */
export interface StripeSubscriptionsModel {
  _id: any;
  subscription: string | ISubscription;
  stripePlanId: string;
  stripeSubscription?: Stripe.Subscription;
  /** optional leave out to use the stripe default payment id on the customer */
  stripePaymentMethod?: string;
  /** key to allow use of multiple subscriptions for use with upsert */
  subscriptionKey: string;
  /** set to `true` to trigger a cancelation request to stripe, set to `false` to cancel the request */
  scheduleCancel?: boolean;
  /** only present if you pass in the plan to preview as `preview_plan_id` query in the get method */
  invoicePreview?: Stripe.Invoice;
}

export interface StripeCheckoutSessions {
  /** subscriber Id from database (create via subscriptions model) */
  subscriberId: string;
  successUrl: string;
  cancelUrl: string;
  /** override the payment method types - by default it's just card */
  paymentMethodTypes?: Stripe.Checkout.SessionCreateParams.PaymentMethodType[];
  /** response from stripe */
  checkoutSessionId?: string;
  stripeResponse?: Stripe.Checkout.Session;
}

export type PlanTypes = 'APS' | 'PAYG'
export type TCurrency = 'gbp' | 'nzd' | 'aud';

export interface ScheduleSubscriptionData {
  /** The future Subscription */
  subscription: string;
  startDateAsUnixTime: number;
  /** stripe payment method id */
  paymentMethod: string;
  /** setup intent for email for bacs debit (optional) */
  setupIntent?: string;
  /** for now always APS - maps to stripe plan id */
  planType: PlanTypes;
  result?: Stripe.SubscriptionSchedule;
  /**
   * Number of modules ordered
   */
  modules: number;
  /**
   * The exact decimal price per module
   */
  perModuleMonthlyPrice: string;
  /**
   * Discount to apply
   */
  discount?: 10 | 20;
  /**
   * Module price for reference purposes
   */
  modulePrice: number;

  /** currency for amount - defaults to gbp */
  currency?: TCurrency;

  /** optional extra items to be billed - note these are not discounted */
  otherItems?: ExtraItem[];

}

export interface ExtraItem {
  /** id of product - will be used to look up exisitng item */
  id: string;
  /** description for product (optional) */
  description?: string;
  /** amount in decimal cents as a string */
  amountDecimal: string;
  /** amount of items */
  quantity: number;
}

export interface IrisLatestState {
  /** url to the last log entry for a reghitid */
  lastLogUrl: string;
}

interface Address {
  name: string;
  address: string;
}
/** same as nodemailer with some extensions */
export interface EmailData {
    /** This is the object to pass into handlebars template */
    context?: Object;
    /** The template to render with */
    template?: string;
    /** The subject of the e-mail */
    subject?: string;
    /** Comma separated list or an array of recipients e-mail addresses that will appear on the To: field */
    to?: string | Address | Array<string | Address>;
}

export interface StripePaymentMethodUpdateData {
  /** subscriber id as in the database subscriber id (mongodb) */
  subscriberId?: string;
  /** new paymentmmethod id */
  paymentMethodId: string;
  /** the setupIntentId */
  setupIntentId?: string;
}

export interface ServiceTypes {
  payments: Service<IPaymentApi>;
  subscriptions: Service<ISubscription>;
  'subscriptions/:subscriberId/setup-intent': Service<ISubscriptionSetupIntent>;
  'stripe/scheduled-payment': Service<IScheduledPayment>;
  'stripe/subscription': Service<StripeSubscriptionsModel>;
  'stripe/sessions': Service<StripeCheckoutSessions>;
  'stripe/payment-methods': Service<Stripe.PaymentMethod>;
  'stripe/scheduled-subscription': Service<ScheduleSubscriptionData>;
  'iris/latest-state': Pick<ServiceMethods<IrisLatestState>, 'get'>;
  'email': Pick<ServiceMethods<EmailData>, 'create'>;
  'stripe/payment-method-update': Pick<ServiceMethods<StripePaymentMethodUpdateData>, 'patch'>;
}
interface FeathersServiceOptions {
  api?: Api
  axios?: AxiosStatic
  url: string
}

export function clientWithOptions ({ api, axios, url } : FeathersServiceOptions) {
  const client = feathers<ServiceTypes>()
  const feathersClient = rest(url)
  if (axios) {
    client.configure(feathersClient.axios(axios))
  }
  if (api) {
    client.configure(feathersClient.axios(autoAuthAxios(api)))
  }
  return client
}
